import { useEffect, useState, useRef, useContext } from 'react'
import { Modal, CustomTooltip, Alert, Input, Checkbox, FileUploadDnD, Select, Option } from '../ui_new'
import byteConverter from '../../helpers/byteConverter'
import { Sort, Pen, ChevronLeft, Sync, Weight, Close, Drag, CheckCircle, Eye } from '../../assets/icons'
import Button from '../UI/Button';
import Loader from '../UI/Loader';
import { EditAttachmentModal } from '.'
import { NotificationContext, LoaderContext, UserContext, DocumentsContext } from '../../context'
import { getFileData, isFeatureAuthorized } from '../../utils'
import { useAttachmentActions } from '../../hooks'
import { AttachmentsContext } from '../../context/attachments/attachmentsState';
import { Settings, Edit, Delete, DragIndicator, ArrowBack, DeleteOutline } from '@mui/icons-material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import moment from 'moment';


const DefaultAttachmentsModal = ({ onClose, docId, template }) => {
  const { setNotification } = useContext(NotificationContext)
  const { userClaims } = useContext(UserContext)
  const { updateTemplate } = useContext(DocumentsContext)
  const [loading, setLoading] = useState(false);
  const [conditions, setConditions] = useState([]);
  const [conditionsRelation, setConditionsRelation] = useState('and'); // 'and' or 'or'
  const [draggedOverItem, setDraggedOverItem] = useState();
  const currentlyDraggedAttachment = useRef(0);
  const draggedOverAttachment = useRef(0);
  const [currentAttachment, setCurrentAttachment] = useState();
  const [editingConditions, setEditingConditions] = useState({ active: false, attachment: null });
  const [edit, setEdit] = useState({ showModal: false, attachment: null });
  const [showWeightAlert, setShowWeightAlert] = useState(false);
  const [showCompressedSizeAlert, setShowCompressedSizeAlert] = useState(false);
  const { setShowGlobalResponseLoader } = useContext(LoaderContext);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false)
  const [orderedAttachments, setOrderedAttachments] = useState([]);
  const { defaultAttachments, defaultAttachmentsOrder, updateDefaultAttachmentsOrder, createMultipleDefaultAttachments, updateDefaultAttachment, deleteDefaultAttachment, compressAttachment } = useContext(AttachmentsContext)

  const fileRef = useRef()

  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    setSelectedOptions(template?.default_attachments || [])
  }, [template])

  // useEffect(() => {
  //   // const rootOrder = defaultAttachmentsOrder || []
  //   // let orderedFoldersCopy = rootOrder.map(id => rootFolders.find(folder => folder.id === id));
  //   // filter out undefined folders & add folders that are not in the order
  //   rootFolders.forEach(folder => {
  //     if (!orderedFoldersCopy.includes(folder)) {
  //       orderedFoldersCopy.push(folder);
  //     }
  //   });
  //   orderedFoldersCopy = orderedFoldersCopy.filter(folder => folder);
  //   setOrderedFolders(orderedFoldersCopy);
  // }, [defaultAttachments, foldersOrderMap]);

  useEffect(() => {
    const attArray = defaultAttachmentsOrder.map(id => defaultAttachments[id] ? { ...defaultAttachments[id], id } : null).filter(att => att)

    for (let key in defaultAttachments) {
      if (!attArray.find(att => att.id === key)) {
        attArray.push({
          ...defaultAttachments[key],
          id: key
        })
      }
    }
    setOrderedAttachments([...attArray])
  }, [defaultAttachments, defaultAttachmentsOrder])

  const optionSelectHandler = (value) => {
    setSelectedOptions(opts => {
      if (opts.includes(value)) {
        return opts.filter(o => o !== value)
      } else {
        return [...opts, value]
      }
    })
  }

  const handleSaveTemplate = async () => {
    setLoading(true)
    setShowGlobalResponseLoader(true)
    try {
      await updateTemplate({ default_attachments: selectedOptions }, template.id);
      setNotification({ msg: `Les pièces jointes du modèle ont été mises à jour avec succès`, type: 'success' });
      onClose()
    } catch (err) {
      console.log(err);
      setNotification({ msg: `Une erreur est survenue, merci de réessayer`, type: 'danger' });
    }
    setLoading(false)
    setShowGlobalResponseLoader(false)
  }

  const onCancelCondition = () => {
    setEditingConditions({ active: false, attachment: null })
  }

  const handleSaveConditions = async () => {
    let areConditionsValid = true
    for (let c of conditions) {
      if (!c.variable || !c.value) {
        areConditionsValid = false
        break
      }
    }
    if (!areConditionsValid) {
      return setNotification({ msg: 'Veuillez remplir toutes les conditions', type: 'danger' })
    }
    setShowGlobalResponseLoader(true)
    await updateDefaultAttachment(editingConditions.attachment.id, { conditions, conditions_relation: conditionsRelation })
    setEditingConditions({ active: false, attachment: null })
    setShowGlobalResponseLoader(false)
  }

  const addCondition = () => {
    setConditions(prev => [...prev, { variable: '', value: '' }]);
  }

  const deleteCondition = (index) => {
    let newConditions = conditions.filter((item, i) => index !== i);
    setConditions(newConditions)
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const handleCloseEditModal = () => {
    setEdit({ showModal: false, attachment: null });
  }

  // On edit 
  const handleEdit = async (name) => {
    const attachmentsNames = orderedAttachments.filter(a => a.id === edit.attachment.id).map(a => a.name)
    if (attachmentsNames.includes(name)) {
      return setNotification({ msg: 'La pièce jointe portant ce nom existe déjà', type: 'danger' })
    }

    setShowGlobalResponseLoader(true)
    await updateDefaultAttachment(edit.attachment.id, { name })
    setShowGlobalResponseLoader(false)
    handleCloseEditModal()
  }

  // On open file window 
  const handleOpenFileWindow = () => {
    if (fileRef.current) {
      fileRef.current.click()
    }
  }

  // On file change
  const handleFileChange = async (e) => {
    const files = e.target.files
    addAttachmentsHelper(files)
  }

  // Copied from SingleDocumentAttachmensUpload.js
  // Upload files helper
  const addAttachmentsHelper = async (files) => {
    if (!files) return

    const allowedTypes = ['application/pdf']

    const promises = []
    let errors = []
    const attNames = orderedAttachments.map(a => a.name)

    const filesArr = Array.from(files)
    for (let i = 0; i < filesArr.length; i++) {
      const file = filesArr[i]
      let components = file.name.split('.')
      components.splice(components.length - 1, 1)
      const name = components.join('.')

      if (attNames.includes(name)) {
        errors.push(`La pièce jointe avec le nom "${name}" existe déjà`)
        continue
      }

      if (!allowedTypes.includes(file.type)) {
        errors.push(`Type de fichier non valide pour la pièce jointe "${name}"`)
        continue
      }

      if (file.size > 50 * 1024 * 1024) {
        errors.push(`Le poids du fichier pour "${name}" est supérieur au poids autorisé (50MB)`)
        continue
      }

      promises.push(getFileData(file))
    }

    if (errors.length > 0) {
      setNotification({ msg: errors.join('.'), type: 'danger' })
    }

    setShowGlobalResponseLoader(true)

    if (promises.length) {
      const data = await Promise.all(promises)
      // for(let i = 0; i < data.length; i++) {
      //   await createDefaultAttachment(data[i])
      // }
      await createMultipleDefaultAttachments(data)

      // onSetDocumentAttachments(prev => [...prev, ...data])
      // setAttachments(prev => [...prev, ...data])
    }
    setShowGlobalResponseLoader(false)
  }

  const changeCondition = (property) => (e) => {
    const newConditions = conditions.map((c, i) => {
      if (i === +e.target.dataset.index) {
        return { ...c, [property]: e.target.value }
      }
      return c;

    })
    setConditions(newConditions)
  }

  // On delete alert open
  const handleOpenDeleteAlert = (attachment) => {
    setCurrentAttachment(attachment)
    setShowDeleteAlert(true);
  }

  // On delete alert close
  const handleCloseDeleteAlert = () => {
    setShowDeleteAlert(false)
  }

  // On delete
  const handleDelete = async () => {
    setShowGlobalResponseLoader(true)
    setShowDeleteAlert(false)
    try {
      await deleteDefaultAttachment(currentAttachment.id)
    } catch (err) {
      console.log(err)
    } finally {
      setShowGlobalResponseLoader(false)
    }
  }

  const onDragEnd = (e) => {
    if (!e.destination) {
      return;
    }
    const sorted = reorder(orderedAttachments, e.source.index, e.destination.index);
    setOrderedAttachments(sorted);
    const newFullOrder = sorted.map(folder => folder.id)
    updateDefaultAttachmentsOrder(newFullOrder)
  };

  const handlePreview = (attachment) => {
    window.open(attachment.url, '_blank', 'noopener,noreferrer')
  }

  return (
    <Modal onClose={onClose}>
      <div className='single-document-attachments'>
        {!editingConditions.active && <>
          <div className='folder-modal-v2__heading'>
            <div className='signature-heading'>
              <div>
                <h1>Sélectionner les documents à mettre à disposition </h1>
                <h2> {template?.name}</h2>
              </div>
            </div>
            <div className="synthesis-form__actions buttons-row">
              <button className="btn btn--transparent" onClick={onClose}>Annuler</button>
              <button
                className='btn-separator'
              >
              </button>
              <button className='button action-btn bg-pink' onClick={handleSaveTemplate}>
                <span>
                  <CheckCircle />Enregistrer
                </span>
              </button>
            </div>
          </div>
          <div className='folder-modal-v2__content'>
            <div className='table-responsive overflow-x-auto'>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="Table">
                  {(provided) => (
                    <table {...provided.droppableProps} ref={provided.innerRef}>
                      <thead>
                        <tr>
                          <th className='signature-attachments-table__column checkbox'></th>
                          <th className='signature-attachments-table__column name'>Nom</th>
                          <th className='signature-attachments-table__column conditions'>
                            Condition(s) applicable(s)
                          </th>
                          <th className='signature-attachments-table__column date'>
                            Date d'ajout
                          </th>
                          <th className='signature-attachments-table__column weight'>
                            Poids
                          </th>
                          <th className='signature-attachments-table__column actions'>
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {orderedAttachments?.map((attachment, index) => (
                          <Draggable
                            key={`draggable_${attachment.id || index}`}
                            draggableId={`${attachment.id || index}`}
                            index={index}
                          >
                            {(provided) => (
                              <tr key={`pdfFile_${index}`}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={`${index === draggedOverItem ? 'dragged-over-item' : ''} ${currentlyDraggedAttachment.current > draggedOverAttachment.current ? 'top' : ''} ${currentlyDraggedAttachment.current < draggedOverAttachment.current ? 'bottom' : ''}`}
                              >
                                <td className='flex row align-center gap-2'>
                                  <CustomTooltip content="Déplacer">
                                    <button className="icon-btn icon-btn--transparent icon-btn--svg-xxl !rounded attachment-reorder-icon"
                                      type="button"
                                    >
                                      <DragIndicator fontSize='small' />
                                    </button>
                                  </CustomTooltip>
                                  {template && isFeatureAuthorized({ userClaims, rule: 'any_admin', resource: 'default_templates' }) &&
                                    <Checkbox
                                      label={""}
                                      checked={selectedOptions.includes(attachment.id)}
                                      onChange={() => optionSelectHandler(attachment.id)}
                                    />}
                                </td>
                                <td className='name'>
                                  <p className="line-clamp-2 !font-semibold">{attachment.name} </p>
                                </td>
                                <td className='conditions'>
                                  <p className="">
                                    {!attachment.conditions || attachment.conditions.length === 0 ? 'Sans condition' : attachment.conditions.map((c) => `${c.variable} = ${c.value}`).join(attachment.conditions_relation === 'or' ? ' OU ' : ' ET ')}
                                  </p>
                                </td>
                                <td className='created'>
                                  <p className="!font-semibold">
                                    {(attachment.meta?.created || attachment.meta?.updated) ? moment(attachment.meta?.created || attachment.meta?.updated).format('DD/MM/YYYY') : 'Pas disponible'}
                                  </p>
                                </td>
                                <td className='size'>
                                  <p className="!font-semibold">{byteConverter(attachment.size)}</p>
                                </td>
                                <td className='actions'>
                                  <div className="flex gap-5">
                                    <CustomTooltip content="Aperçu">
                                      <button className="icon-btn icon-btn--transparent icon-btn--svg-xxl !rounded" type="button" onClick={() => handlePreview(attachment)}>
                                        <Eye fontSize='small' />
                                      </button>
                                    </CustomTooltip>
                                    <CustomTooltip content="Renommer">
                                      <button className="icon-btn icon-btn--transparent icon-btn--svg-xxl !rounded" type="button" onClick={() => setEdit({ showModal: true, attachment: attachment })}>
                                        <Edit fontSize='small' />
                                      </button>
                                    </CustomTooltip>
                                    <CustomTooltip content="Modifier les conditions">
                                      <button className="icon-btn icon-btn--transparent icon-btn--svg-xxl !rounded" type="button" onClick={() => {
                                        setEditingConditions({ active: true, attachment: attachment })
                                        setConditionsRelation(attachment.conditions_relation || 'and')
                                        setConditions(attachment.conditions || [{ variable: '', value: '' }])
                                      }}><Settings fontSize='small' /></button>
                                    </CustomTooltip>

                                    <CustomTooltip content="Supprimer">
                                      <button className="icon-btn icon-btn--transparent icon-btn--svg-xxl !rounded" type="button" onClick={() => handleOpenDeleteAlert(attachment)}>
                                        <Delete fontSize='small' />
                                      </button>
                                    </CustomTooltip>
                                  </div>

                                </td>
                              </tr>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}

                      </tbody>
                    </table>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
            <FileUploadDnD
              label="Glisser-déposer un document"
              buttonPosition="inside-right"
              btnText="Sélectionner un document"
              className="mt-20 mb-20"
              height="sm"
              onChange={(files) => handleFileChange({ target: { files } })}
              accept='application/pdf'
              maxSize={50 * 1024 * 1024}
              multiple={true}
            />
          </div>

        </>}
        {editingConditions.active && <>
          <div className='folder-modal-v2__heading'>
            <div className='heading-with-return-btn'>
              <button className='button button--transparent return' onClick={() => setEditingConditions({ showModal: false, attachment: null })}>
                <ArrowBack fontSize={'small'} />
                Retour
              </button>

              <div>
                <h1 className='modal-title'>Appliquer des conditions</h1>
                <h2>{editingConditions?.attachment?.name}</h2>
              </div>
            </div>
            <div className="synthesis-form__actions buttons-row">
              <Button text="Annuler" onButtonClick={onCancelCondition} outlinePrimary className="btn btn--medium btn--transparent" />
              <button
                className='btn-separator'
              >
              </button>
              <button className="button action-btn" onClick={handleSaveConditions}>
                <span>
                  <CheckCircle />
                  {!loading ? "Enregistrer" : <Loader mini normalWhite />}
                </span>
              </button>

            </div>
          </div>
          <div className='folder-modal-v2__content'>
            <div className='overflow-scroll pb-20'>
              <div className="table-responsive">
                <table className="mb-6">
                  <thead>
                    <tr>
                      <th className='' width={conditions.length > 1 ? "120px" : "0px"}></th>
                      <th className=''>Variable concernée</th>
                      <th className='' width="130px"></th>
                      <th className=''>Valeur d'application de la condition</th>
                    </tr>
                  </thead>
                  <tbody>
                    {conditions?.map((condition, index) =>
                      <tr className='' key={index}>
                        <td className='flex row align-center gap-2'>
                          {conditions.length > 1 && <CustomTooltip content="Supprimer">
                            <button className="icon-btn icon-btn--transparent icon-btn--svg-xxl !rounded" type="button" onClick={() => deleteCondition(index)}><DeleteOutline /></button>
                          </CustomTooltip>}
                          {conditions.length > 1 && index > 0 &&
                            <Select
                              selected={conditionsRelation}
                              onChange={(value) => setConditionsRelation(value)}
                              caretPosition='start'
                            >
                              <Option value="and">ET</Option>
                              <Option value="or">OU</Option>
                            </Select>
                          }
                        </td>
                        <td>
                          <input type='text' placeholder="Nom de variable" className='input input-v2__field ' value={condition.variable} data-index={index} onChange={changeCondition('variable')} />
                        </td>
                        <td>
                          <span>valeur égale à</span>
                        </td>
                        <td>
                          <input type='text' placeholder="Valeur attendue" className='input input-v2__field ' value={condition.value} data-index={index} onChange={changeCondition('value')} />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <button className="button button--with-icon button--outline-primary height-40" onClick={addCondition}>
                + Ajouter une condition
              </button>

            </div>
          </div>
        </>}

        {edit.showModal && (
          <EditAttachmentModal
            onClose={handleCloseEditModal}
            attachment={edit.attachment}
            onEdit={handleEdit}
          />
        )}

        {showDeleteAlert && (
          <Alert
            onClose={handleCloseDeleteAlert}
            text={`Êtes-vous sûr de vouloir supprimer ce document ?`}
            bodyText={``}
            deleteAlert={true}
            onSubmit={handleDelete}
          />
        )}
      </div>
    </Modal>
  )
}

export default DefaultAttachmentsModal