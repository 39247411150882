import { useState, useEffect, useContext, useRef } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { Check, Sort, Eye, Pen, Weight, Download, Upload } from '../../assets/icons'
import { CustomTooltip, Alert, Checkbox } from '../ui_new'
import { EditAttachmentModal, AttachmentsActions } from './'
import { arrayWithOrderedIds, defaultAttachmentsForDocument, FEATURE, getFileData, isFeatureEnabled, printSize, sortArrayOfObjects } from '../../utils'
import { useAttachmentActions } from '../../hooks'
import { NotificationContext, LoaderContext, SingleDocumentContext } from '../../context'
import byteConverter from '../../helpers/byteConverter'
import { AttachmentsContext } from '../../context/attachments/attachmentsState'
import { Compress, Delete } from '@mui/icons-material'
import moment from 'moment'


const SingleDocumentAttachments = ({ attachments, docId, singleDoc, template, onSetDocumentAttachments, onToggleSidePanel, isSidePanelOpen }) => {

  const { filteredDefaultAttachments, filteredAttachmentVariables } = useContext(SingleDocumentContext)
  const attachmentTotalMaxSize = 2.56e+8; // TODO: we used this variable in multiple places, it should be moved to a constant folder.

  const [otherAttachments, setOtherAttachments] = useState([])
  const [requiredAttachmentRows, setRequiredAttachmentRows] = useState([])
  // Set filtered attachments on attachments change
  useEffect(() => {
    if (attachments) {
      let atts = [...attachments]
      for (let i = 0; i < atts.length; i++) {
        let att = atts[i]
        if (!att.id) {
          att.id = uuidv4()
        }
      }
      setOtherAttachments(sortArrayOfObjects(atts, 'name', 'asc').filter(att => !att.variable).map(att => ({
        ...att,
        name_label: att.name,
        size_label: byteConverter(att.size),
        created_label: att.created ? moment(att.created).format('DD/MM/YYYY') : ''
      })))
      const requiredRows = []
      for (let variable of filteredAttachmentVariables) {
        const variableAtts = atts.filter(att => att.variable === variable.variable)
        if (variableAtts.length > 0) {
          requiredRows.push(...variableAtts.map(att => ({
            ...att,
            name_label: variable.display_name || variable.variable,
            file_label: att.name,
            size_label: byteConverter(att.size),
            created_label: att.created ? moment(att.created).format('DD/MM/YYYY') : '',
            status: 'filled'
          })))
        } else {
          requiredRows.push({
            variable: variable.variable,
            name_label: variable.display_name || variable.variable,
            file_label: '-',
            size_label: '-',
            created_label: '-',
            status: 'empty'
          })
        }
      }
      setRequiredAttachmentRows(requiredRows)
    }
  }, [attachments, filteredAttachmentVariables])

  const attachmentsTotalSize = [...(attachments || []), ...(filteredDefaultAttachments || [])].reduce((acc, att) => acc + att.size, 0)

  return (
    <>
      <div className="single-document-attachments">
        {isFeatureEnabled(FEATURE.REQUIRED_ATTACHMENTS) &&
          <AttachmentsTable
            type="variables"
            title="Documents à joindre"
            documentAttachments={attachments}
            filteredAttachments={requiredAttachmentRows}
            onSetDocumentAttachments={onSetDocumentAttachments}
            onToggleSidePanel={onToggleSidePanel}
            isSidePanelOpen={isSidePanelOpen}
            docId={docId}
          />
        }
        {isFeatureEnabled(FEATURE.DEFAULT_ATTACHMENTS) &&
          <AttachmentsTable
            type="default-attachments"
            title="Documents à disposition"
            filteredAttachments={filteredDefaultAttachments}
            docId={docId}
            onSetDocumentAttachments={onSetDocumentAttachments}
          />
        }

        <AttachmentsTable
          type="other"
          title={isFeatureEnabled(FEATURE.REQUIRED_ATTACHMENTS) ? "Autres documents" : 'Documents annexés'}
          documentAttachments={attachments}
          filteredAttachments={otherAttachments}
          docId={docId}
          onSetDocumentAttachments={onSetDocumentAttachments}
        />

        <p className="queta">
          <div className='flex justify-between w-full'>
            <span>Format uniquement supporté : .PDF</span>
            <div>
              <span>Taille maximale : {`${printSize(attachmentTotalMaxSize)}`}</span><br />
              <b>Poids actuel : {`${printSize(attachmentsTotalSize)}`}</b>
            </div>
          </div>
        </p>
      </div>
    </>
  )
}

const AttachmentsTable = ({ title, type, filteredAttachments, documentAttachments, docId, onSetDocumentAttachments, onToggleSidePanel, isSidePanelOpen }) => {

  const { setShowGlobalResponseLoader } = useContext(LoaderContext)
  const { setNotification } = useContext(NotificationContext)
  const { previewAttachment, editAttachment, deleteMultipleAttachments, downloadAttachment, compressAttachment } = useAttachmentActions(null, documentAttachments, docId)
  const { setPrefilledAttachmentVariable } = useContext(AttachmentsContext)

  const [selectedAttachments, setSelectedAttachments] = useState([])
  const [deleteAlert, setDeleteAlert] = useState({ open: false, attachments: [] })
  const [editModal, setEditModal] = useState({ open: false, attachment: null })
  const [compressAlert, setCompressAlert] = useState({
    open: false,
    attachment: null,
  })

  const [currentVariable, setCurrentVariable] = useState('')
  const fileRef = useRef()


  // On file change
  const handleFileChange = async (e) => {
    const files = e.target.files
    addAttachmentsHelper(files)
  }
  const allowedTypes = ['application/pdf']//, 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/png', 'image/jpg', 'image/jpeg', 'image/svg', 'image/svg+xml', 'image/gif']
  // Upload files helper
  const addAttachmentsHelper = async (files) => {
    if (!files) return

    const promises = []
    let errors = []
    const attNames = documentAttachments.map(a => a.name)

    const filesArr = Array.from(files)
    for (let i = 0; i < filesArr.length; i++) {
      const file = filesArr[i]
      let components = file.name.split('.')
      components.splice(components.length - 1, 1)
      const name = components.join('.')

      if (attNames.includes(name)) {
        errors.push(`La pièce jointe avec le nom "${name}" existe déjà`)
        continue
      }

      if (!allowedTypes.includes(file.type)) {
        errors.push(`Type de fichier non valide pour la pièce jointe "${name}"`)
        continue
      }

      if (file.size > 50 * 1024 * 1024) {
        errors.push(`Le poids du fichier pour "${name}" est supérieur au poids autorisé (50MB)`)
        continue
      }

      promises.push(getFileData(file))
    }

    if (errors.length > 0) {
      setNotification({ msg: errors.join('.'), type: 'danger' })
    }

    if (promises.length) {
      const data = await Promise.all(promises)
      onSetDocumentAttachments(prev => [...prev, ...(data.map((d) => ({ ...d, variable: currentVariable || '', created: Date.now() })))])
    }
  }

  let cols, actions
  switch (type) {
    case 'variables':
      cols = [{
        label: 'Nom',
        field: 'name_label',
      }, {
        label: 'Nom du fichier',
        field: 'file_label'
      }, {
        label: 'Date',
        width: '100px',
        field: 'created_label'
      }, {
        label: 'Status',
        width: '100px',
        status: true
      }, {
        label: 'Poids',
        width: '100px',
        field: 'size_label',
      }, {
        label: 'Actions',
        width: '100px',
        actions: true
      }]
      actions = ['preview', 'compress', 'add', 'delete']
      break
    case 'default-attachments':
      cols = [{
        label: 'Nom',
        field: 'name_label',
      }, {
        label: 'Poids',
        width: '100px',
        field: 'size_label',
      }, {
        label: 'Actions',
        width: '100px',
        actions: true
      }]
      actions = ['preview', 'download']
      break
    case 'other':
      cols = [{
        label: 'Nom',
        field: 'name_label',
      }, {
        label: 'Date',
        width: '100px',
        field: 'created_label'
      }, {
        label: 'Poids',
        width: '100px',
        field: 'size_label',
      }, {
        label: 'Actions',
        width: '100px',
        actions: true
      }]
      actions = ['preview', 'compress', 'edit', 'delete']
      break
    default:
      cols = []
      break
  }

  const iconForAction = (action) => {
    switch (action) {
      case 'preview':
        return <Eye />
      case 'download':
        return <Download />
      case 'compress':
        return <Compress />
      case 'edit':
        return <Pen />
      case 'delete':
        return <Delete />
      case 'add':
        return <Upload />
      default:
        return null
    }
  }

  const tooltipForAction = (action) => {
    switch (action) {
      case 'preview':
        return 'Aperçu'
      case 'download':
        return 'Télécharger'
      case 'compress':
        return 'Compresser le document'
      case 'edit':
        return 'Renommer'
      case 'delete':
        return 'Supprimer'
      case 'add':
        return 'Ajouter'
      default:
        return null
    }
  }

  const isActionEnabled = (action, attachment) => {
    if (!attachment.status) {
      return true
    }
    switch (action) {
      case 'preview':
        return attachment.status === 'filled'
      case 'download':
        return attachment.status === 'filled'
      case 'compress':
        return attachment.status === 'filled'
      case 'edit':
        return attachment.status === 'filled'
      case 'delete':
        return attachment.status === 'filled'
      case 'add':
        return attachment.status === 'empty'
      default:
        return false
    }
  }

  const handleAttachmentAction = (action, attachment) => {
    if (action === 'add') {
      handleAddAttachment(attachment)
    } else if (action === 'delete') {
      promptDelete([attachment])
    } else if (action === 'edit') {
      promptEdit(attachment)
    } else if (action === 'preview') {
      previewAttachment(attachment)
    } else if (action === 'download') {
      downloadAttachment(attachment)
    } else if (action === 'compress') {
      promptCompress(attachment)
    }
  }

  const handleAddAttachment = (attachment) => {
    if (!isSidePanelOpen) {
      setPrefilledAttachmentVariable(attachment.variable)
      onToggleSidePanel()
    } else {
      handleOpenFileWindow()
      setCurrentVariable(attachment.variable)
    }
  }
  // On open file window
  const handleOpenFileWindow = () => {
    if (fileRef.current) {
      fileRef.current.click()
    }
  }

  const isSelectionAvailable = () => {
    return type !== 'default-attachments'
  }

  const isSelectionDisabled = (attachment) => {
    return type === 'variables' && attachment.status === 'empty'
  }

  const toggleSelection = (attachment) => {
    if (selectedAttachments.map(a => a.id).includes(attachment.id)) {
      setSelectedAttachments(selectedAttachments.filter(a => a.id !== attachment.id))
    } else {
      setSelectedAttachments([...selectedAttachments, attachment])
    }
  }

  const selectableAttachments = filteredAttachments.filter(att => !isSelectionDisabled(att))

  const toggleAll = () => {
    if (selectedAttachments.length === selectableAttachments.length) {
      setSelectedAttachments([])
    } else {
      setSelectedAttachments([...selectableAttachments])
    }
  }

  const promptDelete = (attachments) => {
    setDeleteAlert({ open: true, attachments: attachments })
  }

  const handleCloseDeleteAlert = () => {
    setDeleteAlert({ open: false, attachments: [] })
  }

  const handleDelete = async () => {

    setShowGlobalResponseLoader(true)
    handleCloseDeleteAlert()
    try {
      const updatedAttachments = await deleteMultipleAttachments(deleteAlert.attachments)
      onSetDocumentAttachments(updatedAttachments)
      setSelectedAttachments(selectedAttachments.filter(a => !deleteAlert.attachments.map(a => a.id).includes(a.id)))
      handleCloseDeleteAlert()
    } catch (err) {
      console.log(err)
    } finally {
      setShowGlobalResponseLoader(false)
    }
  }

  const promptEdit = (attachment) => {
    setEditModal({ open: true, attachment: attachment })
  }

  const handleCloseEditModal = () => {
    setEditModal({ open: false, attachment: null })
  }

  const handleEdit = async (name) => {
    const attachmentsNames = documentAttachments.filter(a => a.id === editModal.attachment.id).map(a => a.name)
    if (attachmentsNames.includes(name)) {
      return setNotification({ msg: 'La pièce jointe portant ce nom existe déjà', type: 'danger' })
    }

    setShowGlobalResponseLoader(true)
    try {
      const updatedAttachments = await editAttachment(name, editModal.attachment)
      onSetDocumentAttachments(updatedAttachments)
      handleCloseEditModal()
    } catch (err) {
      console.log(err)
    } finally {
      setShowGlobalResponseLoader(false)
    }
  }

  const promptCompress = (attachment) => {
    setCompressAlert({ open: true, attachment: attachment })
  }

  const handleCloseCompressAlert = () => {
    setCompressAlert({ open: false, attachment: null })
  }

  const handleCompress = async () => {
    setShowGlobalResponseLoader(true)
    try {
      const originalSize = compressAlert.attachment.size
      const compressionResponse = await compressAttachment(compressAlert.attachment)
      if (compressionResponse.error) {
        console.error(compressionResponse.error)
        return setNotification({ msg: compressionResponse.error || 'Une erreur est survenue lors de la compression du fichier', type: 'danger' })
      }
      const compressedAttachment = compressionResponse.attachment
      const newSize = compressionResponse.newSize
      const updatedAttachments = documentAttachments.map(att => att.id === compressedAttachment.id ? compressedAttachment : att)
      setNotification({ msg: `Le fichier a été compressé avec succès. La taille du fichier a été réduite de ${byteConverter(originalSize - newSize)}`, type: 'success' })
      onSetDocumentAttachments(updatedAttachments)
      handleCloseCompressAlert()
    } catch (err) {
      console.log(err)
    } finally {
      setShowGlobalResponseLoader(false)
    }

  }

  return (
    <>
      <div className="single-document-attachments__head">
        <h2>{title}</h2>
      </div>

      {selectedAttachments.length > 0 && <div className="single-document-attachments__actions mt-2">
        <AttachmentsActions
          selectedAttachments={selectedAttachments}
          onSetSelectedAttachments={setSelectedAttachments}
          onToggleAll={toggleAll}
          allChecked={selectedAttachments.length === selectableAttachments.length}
          onDelete={() => promptDelete(selectedAttachments)}
        />
      </div>}
      <div className='overflow-scroll'>

        <div className="table-responsive mb-5">
          <table>
            <thead>
              <tr>
                <th width="48px">
                </th>
                {cols.map((col, colIndex) => {
                  return (
                    <th key={`table-${type}-head-col-${colIndex}`} width={col.width || 'auto'} className={colIndex === 0 ? '!pl-2' : ''}>
                      {col.label}
                    </th>
                  )
                })}
              </tr>
            </thead>
            <tbody className="documents-table__body">
              {filteredAttachments.map((attachment, attachmentIndex) => {
                return (
                  <tr key={`table-${type}-row-${attachmentIndex}`}>

                    <td>
                      {isSelectionAvailable() && (
                        !isSelectionDisabled(attachment) && <Checkbox checked={selectedAttachments.map(a => a.id).includes(attachment.id)} onChange={() => toggleSelection(attachment)} />
                      )}
                    </td>
                    {cols.map((col, colIndex) => {
                      return (
                        <td key={`table-${type}-row-${attachmentIndex}-col-${colIndex}`} className={colIndex === 0 ? '!pl-2' : ''}>
                          <p className={'!font-semibold'}>
                            {col.field ? attachment[col.field] : attachment[col.label]}
                          </p>
                          {col.actions && (
                            <div className="flex gap-2">
                              {actions.map((action, actionIndex) => {
                                return (
                                  <CustomTooltip key={`table-${type}-row-${attachmentIndex}-col-${colIndex}-action-${actionIndex}`} content={tooltipForAction(action)}>
                                    <button
                                      className="icon-btn icon-btn--transparent icon-btn--svg-xl !rounded"
                                      type="button"
                                      onClick={() => handleAttachmentAction(action, attachment)}
                                      disabled={!isActionEnabled(action, attachment)}
                                    >
                                      {iconForAction(action)}
                                    </button>
                                  </CustomTooltip>
                                )
                              })}
                            </div>
                          )}
                          {col.status && (
                            <span className={`status status--${attachment.status}`}>{attachment.status === 'filled' ? 'Ajouté' : 'Manquant'}</span>
                          )}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>

      {deleteAlert.open && <Alert
        onClose={handleCloseDeleteAlert}
        text={deleteAlert.attachments.length > 1 ? "Êtes-vous sûr de vouloir supprimer ces documents ?" : `Êtes-vous sûr de vouloir supprimer ce document ?`}
        onSubmit={handleDelete}
        deleteAlert
      />}
      {editModal.open && (
        <EditAttachmentModal
          onClose={handleCloseEditModal}
          attachment={editModal.attachment}
          onEdit={handleEdit}
        />
      )}
      {compressAlert.open && (
        <Alert
          onClose={handleCloseCompressAlert}
          text={`${compressAlert.attachment.name}`}
          bodyText={`Reduire le poids du fichier pdf? A présent ${byteConverter(compressAlert.attachment.size)}`}
          onSubmit={handleCompress}
        />
      )}
      <input className='input-hidden' type="file" ref={fileRef} onChange={handleFileChange} multiple accept="application/pdf" />
    </>
  )
}

export default SingleDocumentAttachments
