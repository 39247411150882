import { useContext } from 'react' 
import { Route, Switch, Redirect } from 'react-router-dom'

import { Dashboard, Documents, Templates, SettingsProfile, Signatures, SingleDocument, SettingsAgency, SettingsCoverPage, Help, RegisteredMailTracking, RegisteredMailSender, StandardTemplates, StandardTemplateDetail } from '../pages'
import { ResponseLoader } from '../components/ui_new'
import { LoaderContext, SingleDocumentState, UserContext } from '../context'
import { FEATURE, availableOn, canUseCoverPageSettings, isFeatureEnabled } from '../utils'
import WhatsNew from '../components/pages/support/WhatsNew'
import HelpPage from '../components/pages/support/Help'
import Documentation from '../components/pages/support/Documentation'
import HelpCategory from '../components/pages/support/HelpCategory'
import HelpArticle from '../components/pages/support/HelpArticle'
import HelpSearchResults from '../components/pages/support/HelpSearchResults'
import WhatsNewArticle from '../components/pages/support/WhatsNewArticle'
import DocumentationArticle from '../components/pages/support/DocumentationArticle'
import NotFound from '../components/pages/NotFound'

const AppRoutes = ({ token }) => {
  const { showDashboardOverlay, showGlobalResponseLoader, globalResponseLoaderText } = useContext(LoaderContext)  
  const { user, partner, showSignatures, siteConfigs } = useContext(UserContext)

  const editorCode = () => {
    if(partner === 'jurisur') {
      return 'ac3'
    }
    return partner
  }

  return (
    <div className="app-wrapper" data-editeur-code={editorCode()}>
      {showDashboardOverlay && <div className="overlay"></div>}
      {showGlobalResponseLoader && <ResponseLoader text={globalResponseLoaderText} />}
      <Switch>
        <Route path="/" exact>
          <Dashboard />
        </Route>
        <Route path="/documents" exact>
          <Documents />
        </Route>
        <Route path="/documents/:id" exact>
          <SingleDocumentState>
            <SingleDocument token={token} pageTitle={"Rédaction d'un document"}/>
          </SingleDocumentState>
        </Route>
        <Route path="/document-detail/:id" exact>
          <SingleDocumentState>
            <SingleDocument token={token} pageTitle={"Rédaction d'un document"}/>
          </SingleDocumentState>
        </Route>
        <Route path="/templates" exact>
          <Templates />
        </Route>
        <Route path="/templates/:id" exact>
          <SingleDocumentState>
            <SingleDocument token={token} pageTitle={"Rédaction d'un document"}/>
          </SingleDocumentState>
        </Route>
        <Route path="/template/:id" exact>
          <SingleDocumentState>
            <SingleDocument token={token} pageTitle={"Rédaction d'un document"}/>
          </SingleDocumentState>
        </Route>
        <Route path="/standard-templates" exact>
          <StandardTemplates standard />
        </Route>
        <Route path="/standard-templates/:id" exact>
          <StandardTemplateDetail token={token} />
        </Route>
        {showSignatures && <Route path="/signatures" exact>
          <Signatures />
        </Route>}
        
        <Route path="/settings/profile" exact>
          <SettingsProfile />
        </Route>
        <Route path="/settings/my-profile" exact>
          <SettingsProfile />
        </Route>
        <Route path="/settings/agency" exact>
          <SettingsAgency />
        </Route>
        {isFeatureEnabled(FEATURE.COVER_PAGE_SETTINGS, [], partner, user) && <Route path="/settings/cover-page" exact>
          <SettingsCoverPage />
        </Route>}
        {availableOn(['development', 'staging']) &&  <Route path="/help" exact>
          <Help />
        </Route> }
        <Route path="/what-is-new" exact>
          <WhatsNew v2 />
        </Route>
        {availableOn(['development', 'staging']) &&<Route path="/help-center" exact>
          <HelpPage v2 />
        </Route> }
        <Route path="/documentation" exact>
          <Documentation v2 />
        </Route>
        {availableOn(['development', 'staging']) &&<Route path="/help-category/:id" exact>
          <HelpCategory v2 />
        </Route> }
        {availableOn(['development', 'staging']) &&<Route path="/help-article/:id" exact>
          <HelpArticle v2 />
        </Route>}
        {availableOn(['development', 'staging']) && <Route path="/what-is-new" exact>
          <WhatsNew v2 />
        </Route>}
        {availableOn(['development', 'staging']) &&  <Route path="/help-center" exact>
          <HelpPage v2 />
        </Route>}
        {availableOn(['development', 'staging']) && <Route path="/documentation" exact>
          <Documentation v2 />
        </Route>}
        {availableOn(['development', 'staging']) && <Route path="/help-category/:id" exact>
          <HelpCategory v2 />
        </Route>}
        {availableOn(['development', 'staging']) && <Route path="/help-article/:id" exact>
          <HelpArticle v2 />
        </Route>}
        {availableOn(['development', 'staging']) && <Route path="/help-search-results" exact>
          <HelpSearchResults v2 />
        </Route>}
        {availableOn(['development', 'staging']) && <Route path="/what-is-new/:id" exact>
          <WhatsNewArticle v2 />
        </Route>}
        {availableOn(['development', 'staging']) && <Route path="/documentation/:id" exact>
          <DocumentationArticle v2 />
        </Route>}

        {isFeatureEnabled(FEATURE.AR24, siteConfigs) &&<Route path="/registered-mail/tracking" exact>
          <RegisteredMailTracking />
        </Route>}
        {isFeatureEnabled(FEATURE.AR24, siteConfigs) &&<Route path="/registered-mail/sender" exact>
          <RegisteredMailSender />
        </Route>}
        {isFeatureEnabled(FEATURE.AR24, siteConfigs) &&<Route path="/registered-mail" exact>
          <Redirect to="/registered-mail/tracking" />
        </Route>}

        <Route path="*">
          <NotFound />
        </Route>
        <Route path="*">
          <NotFound v2 />
        </Route>
      </Switch>
    </div>
  )
}

export default AppRoutes 