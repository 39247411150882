import { useContext, useState, useEffect, useRef, useCallback } from 'react'

import { FileOpen, Archive, Delete, PlusCircleOutline, CaretRight, FolderZip } from '../../assets/icons'
import { Select, Option, DateInput } from '../ui_new'
import { FolderListItem, CreateEditFolderModal } from './'
import { STATUS_LABELS_SHORT } from '../../constants'
import { DocumentsFoldersContext, DocumentsContext, UserContext, FolderContext } from '../../context'
import { useFoldersActions, useSingleDocumentActions } from '../../hooks'
import { FEATURE, areFolderActionsAuthorized, folderItemsForMobileSidebar, isFeatureAuthorized, isFeatureEnabled, manufacturerProperty } from '../../utils'
import { ArrowRight } from '@material-ui/icons'
import CaretDownOutline from '../../assets/icons/CaretDownOutline'
import { Add } from '@mui/icons-material'

const DocumentsContentSidebar = ({ onSetSelectedFilters, selectedFilters, onChangeCurrent }) => {
  const { moveToRootFolder } = useFoldersActions()
  const { moveDocToRootFolder } = useSingleDocumentActions()
  const { docFolders } = useContext(DocumentsFoldersContext)
  const { currentFilter, documents } = useContext(DocumentsContext)
  const { foldersOrderMap, updateFoldersOrder, folders, standardTemplatesFolders } = useContext(FolderContext)
  const { user, userClaims } = useContext(UserContext)
  const [rootFolders, setRootFolders] = useState([])
  const [activeFolder, setActiveFolder] = useState('all')
  const [showCreateFolderModal, setShowCreateFolderModal] = useState(false)
  // const [status, setStatus] = useState(selectedFilters.status)
  const [dateAfter, setDateAfter] = useState(selectedFilters.date_after)
  const [dateBefore, setDateBefore] = useState(selectedFilters.date_before)

  const [authors, setAuthors] = useState([])

  useEffect(() => {
    if (documents) {
      setAuthors(Object.values(documents).filter(doc => doc.author).filter((doc, index, docs) => {
        return docs.findIndex(d => d.author.id === doc.author.id) === index
      }).map(doc => doc.author))
    }
  }, [documents])

  // Set root folders
  useEffect(() => {
    if (docFolders && Array.isArray(docFolders)) {
      setRootFolders(docFolders.filter(folder => !folder.parentFolder))
    }
  }, [docFolders])

  // Set active folder
  useEffect(() => {
    setActiveFolder(selectedFilters.folder)
  }, [selectedFilters])

  // On status change
  const handleStatusChange = (value) => {
    // setStatus(value)
    onSetSelectedFilters(prev => ({
      ...prev,
      status: value
    }))
  }

  const handleOwnerChange = (value) => {
    onSetSelectedFilters(prev => ({
      ...prev,
      owner: value
    }))
  }

  const handleAuthorChange = (value) => {
    onSetSelectedFilters(prev => ({
      ...prev,
      author: value
    }))
  }

  // On date change - after
  const handleDateAfterChange = (date) => {
    setDateAfter(date)
    onSetSelectedFilters(prev => ({
      ...prev,
      date_after: date
    }))
  }

  // On date change - before
  const handleDateBeforeChange = (date) => {
    setDateBefore(date)
    onSetSelectedFilters(prev => ({
      ...prev,
      date_before: date
    }))
  }

  // On all folders filter
  const handleFilterAllFolders = () => {
    setActiveFolder('all')
    onSetSelectedFilters(prev => ({ ...prev, folder: 'all' }))
  }

  // On folders filter
  const handleFilterFolders = (id) => {
    setActiveFolder(id)
    onSetSelectedFilters(prev => ({ ...prev, folder: id }))
  }

  // Drag over - all
  const handleDragOver = (e) => {
    e.preventDefault()
    const folderBox = e.target.closest('.folder-list-item__main')
    if (!folderBox.classList.contains('active')) {
      folderBox.classList.add('active')
    }
  }

  // Drag leave - all
  const handleDragLeave = (e) => {
    e.preventDefault()
    const folderBox = e.target.closest('.folder-list-item__main')
    if (folderBox.classList.contains('active')) {
      folderBox.classList.remove('active')
    }
  }

  // On drop
  const handleDrop = async (e) => {
    const folderBox = e.target.closest('.folder-list-item__main')
    if (folderBox.classList.contains('active')) {
      folderBox.classList.remove('active')
    }

    const data = e.dataTransfer.getData('folder') ? JSON.parse(e.dataTransfer.getData('folder')) : null

    if (data) {
      moveToRootFolder(data, 'documents')
      return
    }

    const documentData = e.dataTransfer.getData('document') ? JSON.parse(e.dataTransfer.getData('document')) : null

    if (documentData) {
      moveDocToRootFolder(documentData)
    }
  }

  // On create folder icon click
  const handleOpenCreateFolderModal = () => {
    setShowCreateFolderModal(true)
  }

  // On close create folder modal
  const handleCloseCreateFolderModal = () => {
    setShowCreateFolderModal(false)
  }

  // On current documents change
  const handleChangeCurrentFilter = (filter) => {
    onChangeCurrent(filter)
    // setStatus('all')
    setDateAfter('')
    setDateBefore('')
  }

  // On date after clear
  const handleDateAfterClear = () => {
    setDateAfter('')
    onSetSelectedFilters(prev => ({ ...prev, date_after: '' }))
  }

  // On date before clear
  const handleDateBeforeClear = () => {
    setDateBefore('')
    onSetSelectedFilters(prev => ({ ...prev, date_before: '' }))
  }

  // drag and drop to reorder folders
  const [orderedFolders, setOrderedFolders] = useState([]);
  const [draggedOverItem, setDraggedOverItem] = useState('');
  const [parent, setParent] = useState('');

  useEffect(() => {
    const rootOrder = foldersOrderMap?.documents?.root || [];
    let orderedFoldersCopy = rootOrder.map(id => rootFolders.find(folder => folder.id === id));
    // filter out undefined folders & add folders that are not in the order
    rootFolders.forEach(folder => {
      if (!orderedFoldersCopy.includes(folder)) {
        orderedFoldersCopy.push(folder);
      }
    });
    orderedFoldersCopy = orderedFoldersCopy.filter(folder => folder);
    setOrderedFolders(orderedFoldersCopy);
  }, [rootFolders, foldersOrderMap]);

  const dragFolder = useRef(0);
  const draggedOverFolder = useRef(0);

  function reorder() {
    const orderedFoldersClone = [...orderedFolders];
    const movedFolder = orderedFoldersClone.splice(dragFolder.current, 1)[0];
    orderedFoldersClone.splice(draggedOverFolder.current, 0, movedFolder);
    setOrderedFolders(orderedFoldersClone);

    const newFullOrder = { ...(foldersOrderMap?.documents || {}) }
    newFullOrder['root'] = orderedFoldersClone.map(folder => folder.id)
    updateFoldersOrder('documents', newFullOrder)

    setDraggedOverItem();
    setParent();
  }

  const foldersDropdownItems = useCallback(() => {
    const items = folderItemsForMobileSidebar(docFolders, rootFolders, activeFolder)
    const optionsMap = items.map((folder, idx) => {
      return (
        <Option key={`folders_dropdown_item_${idx}`} value={folder.id} preventHide={folder.hasChildren} label={folder.label}>
          <div className='flex flex-row justify-between'>{folder.label}{folder.hasChildren && <span className={`svg-icon ${!folder.isOpen ? 'rotate-270' : ''}`}><CaretDownOutline /></span>}</div>
        </Option>
      )
    })

    if (areFolderActionsAuthorized('documents', userClaims)) {
      optionsMap.push(
        <Option key={`folders_dropdown_item_create`} value={''} defaultAction={handleOpenCreateFolderModal} preventHide={false} label={'Créer un dossier'}>
          <div className='flex flex-row justify-between gap-2'><span className='svg-icon'><PlusCircleOutline /></span>Créer un dossier</div>
        </Option>
      )
    }

    return optionsMap
  }, [rootFolders, activeFolder, docFolders, userClaims])

  return (
    <div className="documents-content__sidebar u-custom-scrollbar" >
      <div className="documents-content__sidebar_head">
        <h1><span>Mes documents</span></h1>
      </div>

      <section className="sidebar-folders current-documents sm-d-none">
        <ul>
          <li>
            <div className="folder-list-item">
              <div
                className={`folder-list-item__main root ${currentFilter === 'all' ? 'active' : ''}`}
                onClick={() => handleChangeCurrentFilter('all')}
              >
                <p><span className="text"><FileOpen /> Documents actifs</span></p>
              </div>
            </div>
          </li>
          <li>
            <div className="folder-list-item">
              <div
                className={`folder-list-item__main root ${currentFilter === 'archived' ? 'active' : ''}`}
                onClick={() => handleChangeCurrentFilter('archived')}
              >
                <p><span className="text"><Archive /> Archives</span></p>
              </div>
            </div>
          </li>
          <li>
            <div className="folder-list-item">
              <div
                className={`folder-list-item__main root ${currentFilter === 'deleted' ? 'active' : ''}`}
                onClick={() => handleChangeCurrentFilter('deleted')}
              >
                <p><span className="text"><Delete /> Supprimés</span></p>
              </div>
            </div>
          </li>
        </ul>
      </section>
      <section className="sidebar-folders sm-d-flex flex flex-row">
        <Select className="!h-8" onChange={handleChangeCurrentFilter} selected={currentFilter} type="filled">
          <Option value="all">Documents actifs</Option>
          <Option value="archived">Archives</Option>
          <Option value="deleted">Supprimés</Option>
        </Select>
        {currentFilter === 'all' && (<Select className="pl-3 !h-8" onChange={(folderId) => handleFilterFolders(folderId)} selected={activeFolder} type="filled">
          {foldersDropdownItems()}
        </Select>)}
      </section>

      {/* 
      <section className="sidebar-filters-section">
        <h3>Filtres</h3>
        {isFeatureEnabled(FEATURE.INTERAGENCY) &&
        <div className="sidebar-filters-section__item">
          <Select label="Créé par" onChange={handleAuthorChange} selected={selectedFilters.author}>
            <Option value="all">Tous</Option>
            { authors.map((author, index) => (
              <Option key={index} value={author.id}>{`${author.firstname} ${author.lastname}`}</Option>
            ))}
          </Select>
        </div> }
        {isFeatureEnabled(FEATURE.INTERAGENCY) && <div className="sidebar-filters-section__item">
          <Select label="Agence d'affectation" onChange={handleOwnerChange} selected={selectedFilters.owner}>
            <Option value="all">Tous</Option>
            { (user.agencies ? user.agencies : [user.agency]).map((agency, index) => (
              <Option key={index} value={manufacturerProperty(agency, 'id')}>{manufacturerProperty(agency, 'name')}</Option>
            ))}
          </Select>
        </div>}
        <div className="sidebar-filters-section__item">
          <DateInput 
            label="Créé après" 
            onChange={handleDateAfterChange} 
            onChangeRaw={(e) => e.preventDefault()}
            isClearable={dateAfter} 
            onClearableClick={handleDateAfterClear}
            selected={dateAfter}
            maxDate={dateBefore}
          />
        </div>
        <div className="sidebar-filters-section__item">
          <DateInput 
            label="Créé avant" 
            onChange={handleDateBeforeChange} 
            onChangeRaw={(e) => e.preventDefault()}
            isClearable={dateBefore} 
            onClearableClick={handleDateBeforeClear}
            selected={dateBefore}
            minDate={dateAfter}
          />
        </div>
        <div className="sidebar-filters-section__item">
          <Select label="Statut" onChange={handleStatusChange} selected={selectedFilters.status}>
            <Option value="all">Tous</Option>
            {Object.entries(STATUS_LABELS_SHORT).map((status, index) => (
              <Option key={index} value={status[0]}>{status[1]}</Option>
            ))}
          </Select>
        </div>
      </section>
       */}

      {currentFilter === 'all' && (
        <section className="sidebar-folders sm-d-none">
          <h3>Dossiers</h3>
          <ul>
            <li>
              <div className="folder-list-item">
                <div
                  className={`folder-list-item__main root ${activeFolder === 'all' ? 'active' : ''}`}
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDrop}
                >
                  <p onClick={handleFilterAllFolders}><span className="text"><FolderZip />Dossier racine</span></p>
                  {areFolderActionsAuthorized('documents', userClaims) && <span className="cursor-pointer" onClick={handleOpenCreateFolderModal}><Add /></span>}
                </div>
                <div className="folder-list-item__body"></div>
              </div>
            </li>
            {orderedFolders.map((folder, idx) => {
              return (
                <li key={`folder_root_${idx}`}
                  className={`pl-2 ${idx === draggedOverItem ? 'dragged-over-item ' : ' '}
                   ${(parent === orderedFolders[draggedOverFolder.current]?.parentFolder && dragFolder.current > draggedOverFolder.current) ? 'top ' : ' '} 
                   ${(parent === orderedFolders[draggedOverFolder.current]?.parentFolder && dragFolder.current < draggedOverFolder.current) ? 'bottom ' : ' '}`}>
                  <div className='flex'>
                    <FolderListItem
                      folder={folder}
                      onFilter={handleFilterFolders}
                      activeFolder={activeFolder}
                      view="documents"
                      level="first"
                      reorderDraggable={areFolderActionsAuthorized('documents', userClaims)}
                      reorderDragStart={() => { dragFolder.current = idx; setParent(folder.parentFolder) }}
                      reorderDragEnter={() => (draggedOverFolder.current = idx)}
                      reorderDragEnd={reorder}
                      reorderDragOver={(e) => { e.preventDefault(); setDraggedOverItem(idx) }}
                    />
                  </div>
                </li>
              )
            })}
          </ul>
        </section>
      )}

      {showCreateFolderModal && (
        <CreateEditFolderModal
          onClose={handleCloseCreateFolderModal}
        />
      )}
    </div>
  )
}

export default DocumentsContentSidebar